import React, { useEffect, useState, useContext } from "react";
import { graphql } from "gatsby";
import styled, { keyframes } from "styled-components";
import Media from "react-media";
import Div100vh from "react-div-100vh";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";
import WindowWidthContext from "../components/context/window-width";

// Components
import { DesktopHomepage } from "../components/homepage/desktop-homepage";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  height: 100%;

  transition: 250ms opacity ease;
  opacity: ${props => props.opacity};

  padding: 180px 100px;

  @media (max-width: 900px) {
    padding: 180px 50px;
  }

  @media (max-width: 767px) {
    padding: 180px 20px;
  }

  @media (max-width: 320px) {
    align-items: unset;
  }
`;

const animateBg = keyframes`
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
`;

const Text = styled.div`
  color: ${props => props.textColor};

  max-width: 960px;
  margin: 0 auto;

  font-size: calc(25px + (42 - 25) * ((100vw - 300px) / (1500 - 300)));

  line-height: 115%;
  text-align: center;

  z-index: 1;

  & p {
    margin: 0;
  }

  & a {
    position: relative;
    display: inline-block;
    vertical-align: bottom;

    overflow: hidden;

    background: linear-gradient(
      to right,
      rgba(223, 63, 28, 1),
      rgba(223, 63, 28, 1) 50%,
      ${props => props.textColor} 50%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.25s ease;

    &::after {
      content: "";
      position: absolute;
      z-index: 100;
      left: 0;
      bottom: 1px;
      animation: ${animateBg} 5s linear infinite;
      background-image: linear-gradient(
        90deg,
        rgba(223, 63, 28, 0.9) 42%,
        rgba(223, 63, 28, 0.25) 50%,
        rgba(223, 63, 28, 0.9) 58%
      );
      background-size: 400% 100%;
      height: 2px;
      width: 100%;
      transition: left 0.3s ease-in-out;
    }

    &:hover {
      background-position: 0 100%;

      &::after {
        left: 100%;
      }
    }
  }
`;

const IndexContent = ({ data, setPageType }) => {
  const windowWidth = useContext(WindowWidthContext);
  const [opacity, setOpacity] = useState(0);
  const [isVideoPlaying, setVideoIsPlaying] = useState(false);
  const [shouldTextFadeOut, setTextFadeOut] = useState(false);

  useEffect(() => {
    setPageType("home");

    setTimeout(() => {
      setOpacity(1);
    }, 250);
  }, []);

  return (
    <Div100vh>
      <Page opacity={opacity}>
        <Text
          textColor={
            isVideoPlaying === true && shouldTextFadeOut === true
              ? `#fff`
              : `#000`
          }
          id="homepage-text"
          dangerouslySetInnerHTML={{
            __html: data.prismicHomepage.data.text.html,
          }}
        />

        <Media
          queries={{ medium: "(min-width: 769px)" }}
          defaultMatches={{ medium: windowWidth === 769 }}
          render={() => (
            <DesktopHomepage
              isVideoPlaying={isVideoPlaying}
              setVideoIsPlaying={setVideoIsPlaying}
              setTextFadeOut={shouldTextFadeOut =>
                setTextFadeOut(shouldTextFadeOut)
              }
              shouldVideoFadeIn={shouldTextFadeOut}
            />
          )}
        />
      </Page>
    </Div100vh>
  );
};

const Index = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <IndexContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      prismicId
      data {
        text {
          html
          raw
        }
      }
    }
  }
`;
