import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const AllVideosContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 0;

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease;
`;

const BackgroundVideo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease;

  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DesktopHomepage = ({
  isVideoPlaying,
  setVideoIsPlaying,
  setTextFadeOut,
  shouldVideoFadeIn,
}) => {
  const [isVideoActive, setVideoActive] = useState(false);
  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        prismicId
        data {
          video_links {
            video_link {
              url
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const textContainer = document.querySelector(`#homepage-text`);
    const allLinks = Array.from(textContainer.getElementsByTagName("a"));

    allLinks.forEach((item, index) => {
      item.addEventListener("mouseover", event => {
        setCurrentVideoPlayerId(index);
      });

      item.addEventListener("mouseleave", event => {
        setCurrentVideoPlayerId(null);
      });
    });
  }, [data]);

  useEffect(() => {
    if (currentVideoPlayerId !== null) {
      setVideoActive(true);
      setVideoIsPlaying(true);
    }

    if (currentVideoPlayerId === null) {
      setVideoActive(false);
      setVideoIsPlaying(false);
    }
  }, [currentVideoPlayerId]);

  const allVideos = data.prismicHomepage.data.video_links.map(
    (content, index) => (
      <BackgroundVideo
        key={`homepage_video_${index}`}
        opacity={
          currentVideoPlayerId === index && shouldVideoFadeIn === true ? 1 : 0
        }
      >
        <Helmet>
          <link rel="preconnect" as="video" href={content.video_link.url} />
        </Helmet>
        <ReactPlayer
          url={content.video_link.url}
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          playing={currentVideoPlayerId === index ? true : false}
          onProgress={progress =>
            progress.loadedSeconds > 1
              ? setTextFadeOut(true)
              : setTextFadeOut(false)
          }
          config={{
            file: {
              attributes: { preload: "metadata" },
            },
          }}
        />
      </BackgroundVideo>
    )
  );
  return <AllVideosContainer>{allVideos}</AllVideosContainer>;
};
